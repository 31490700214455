table {
  @apply min-w-full divide-y divide-gray-200 text-gray-500 text-sm;
}
thead {
  @apply bg-gray-100;
}
.table-light thead {
  @apply bg-gray-50;
}
th {
  @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap;
}
tbody {
  @apply bg-white divide-y divide-gray-200;

  tr:hover td {
    @apply bg-gray-50;
  }

  td {
    @apply px-6 py-4;

    &.actions {
      @apply text-right text-sm font-medium;
    }
  }
}
tr {

  td.actions  {
    padding-left: 0;
    padding-right: 0;
    .btn-actions, .btn-inline-group, .btn {
      visibility: hidden;
      //opacity: 0;
      //transition: all 0.1s ease-in-out;
    }
  }
  &:hover td.actions {
    .btn-actions, .btn-inline-group, .btn {
      visibility: visible;
      //opacity: 1;
    }
  }
  .btn-actions {
    display: inline-flex;
    padding: 2px;
  }
  .btn-actions label {
    font-size: 0.65rem;
    line-height: 1;
    align-self: center;
    position: relative;
    top: 1.25px;
  }
}
.table-scroll-x {
  overflow-x: auto;

  table {
    min-width: 100%;
  }
  tr {
    position: relative;
  }
  th, td {
    text-wrap: nowrap;
  }

  th:last-child,
  td:last-child {
    position: sticky;
    right: 0;
    background: #fff;
    padding-left: 14px;
    padding-right: 14px;
  }

  //th:last-child::after,
  //td:last-child::after {
  //  content: "";
  //  position: absolute;
  //  right: -6px;
  //  top: 0;
  //  bottom: -1px;
  //  width: 5px;
  //  border-left: 1px solid #d1d5db;
  //  background: linear-gradient(
  //      90deg,
  //      rgba(0, 0, 0, 0.08) 0%,
  //      rgba(0, 0, 0, 0) 100%
  //  );
  //}

  th:last-child::before,
  td:last-child::before {
    content: "";
    position: absolute;
    left: -6px;
    top: 0;
    bottom: -1px;
    width: 5px;
    border-right: 1px solid #d1d5db;
    background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.08) 100%
    );
  }
}

.table-scroll-y {
  overflow-y: auto;

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}
