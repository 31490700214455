// Light Jumpstart theme for Pagy's default renderer

.pagination {
  list-style: none;
  @apply inline-flex flex-wrap items-center justify-start;

  .page-item {
    &:first-child a, &:first-child {
      @apply rounded-tl rounded-bl;
    }

    &:last-child a, &:last-child {
      @apply rounded-tr rounded-br;
    }
  }

  .disabled, .gap {
    @apply border-none relative block leading-tight bg-white text-gray-500 no-underline;
  }

  .page-item {
    //&.active {
    //  @apply border border-primary-500 bg-primary-500 text-white relative block leading-tight no-underline;
    //  margin-left: -1px;
    //}

    a {
      @apply border relative block text-sm leading-tight bg-white text-primary-600 no-underline py-2;
      margin-left: -1px;
      padding-left: 0.85rem;
      padding-right: 0.85rem;

      &:hover {
        @apply bg-gray-200;
      }
    }

    &.active a {
      @apply border-primary-600 bg-primary-600 text-white;
    }
  }
}

.pagination-footer {
  @apply px-5 py-5 flex items-center justify-between border-t border-gray-200;
}
