.card {
  @apply bg-white overflow-hidden shadow px-4 py-5 sm:rounded-lg sm:p-6;

  .card-row {
    @apply md:grid md:grid-cols-3 md:gap-6;
  }

  .card-header {
    @apply bg-white px-4 py-5 border-b border-gray-200 sm:px-6;

    .card-header-row {
      @apply -ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap;
      .col {
        @apply ml-4 mt-2;
        &:last-child {
          @apply flex flex-shrink-0 justify-end;
        }
      }
    }
    .card-heading {
      @apply text-lg leading-6 font-medium text-gray-900;
    }
    p {
      @apply mt-1 text-sm text-gray-500;
    }
  }

  h3 {
    @apply text-lg font-medium leading-6 text-gray-900;
  }
  .lead {
    @apply mt-1 text-sm text-gray-500;
  }
}

.lead {
  @apply mt-1 text-sm text-gray-500;
}

:not(.grid) > .card + .card {
  @apply mt-6;
}