//.sync-flow {
//  list-style: none
//}
//.sync-flow  a {
//  text-decoration: none;
//}
//.sync-flow::after {
//  clear: both;
//  content: "";
//  display: table;
//}
//
//.sync-flow li {
//  display: inline-block;
//  float: left;
//  margin: 6px 0;
//  line-height: 1;
//}
//
//.sync-flow li::after {
//  color: $body-color;
//  content: "»";
//  display: inline-block;
//  margin: 0 6px;
//}
//
//.sync-flow li:last-of-type::after {
//  display: none;
//}
//
//.sync-flow li>* {
//  color: $body-color;
//  display: inline-block;
//  font-size: 12px;
//}
//
//.sync-flow .bi {
//  position: absolute;
//  height: 11px;
//  left: 10px;
//  margin-top: -0.5px;
//}
//
//@media only screen and (min-width: 768px) {
//
//  .sync-flow li>* {
//    font-size: 12px;
//  }
//
//  .sync-flow {
//    background-color: transparent;
//    padding: 0;
//  }
//
//  .sync-flow li {
//    margin: 4px 4px 4px 0;
//    padding: 0;
//    position: relative;
//  }
//
//  .sync-flow li:last-of-type {
//    margin-right: 0;
//  }
//
//  .sync-flow li>* {
//    --tw-ring-color: rgb(107 114 128 / 0.1);
//    @apply bg-gray-100 border-gray-100 text-gray-700;
//    padding: 3px 8px 3px 13px;
//    position: relative;
//    font-weight: 500;
//  }
//  .sync-flow li:has(.bi)>* {
//    padding-left: 22px
//  }
//
//  .sync-flow li.step-success>* {
//    --tw-ring-color: rgb(22 163 74 / 0.2);
//    @apply bg-green-50 border-green-50 text-green-700;
//  }
//
//  .sync-flow li:first-of-type>* {
//    border-radius: 3px 0 0 3px;
//    padding-left: 10px;
//  }
//  .sync-flow li:first-of-type:has(.bi)>* {
//    padding-left: 16px;
//  }
//  .sync-flow li:first-of-type .bi {
//    left: 4px;
//  }
//
//  .sync-flow li:last-of-type>* {
//    border-radius: 0 3px 3px 0;
//    padding-right: 10px;
//  }
//
//  .sync-flow li::after, .sync-flow li>::after {
//    border-style: solid;
//    border-width: 9px 0 9px 9px;
//    content: "";
//    height: 0;
//    left: 100%;
//    position: absolute;
//    top: 0;
//    width: 0;
//  }
//
//  .sync-flow li::after {
//    border-color: transparent transparent transparent #fff;
//    margin: 0;
//    transform: translateX(3px);
//    z-index: 1;
//  }
//
//  .sync-flow li>::after {
//    border-color: transparent;
//    border-left-color: inherit;
//    z-index: 2;
//  }
//
//  .sync-flow li:last-of-type::after, .sync-flow li:last-of-type>::after {
//    display: none;
//  }
//}


.chip-nav {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
}

.chip-nav {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 100%;
}

.chip-nav .chip-container {
  display: inline-flex;
  height: 20px;
}
.chip-nav .chip-container .chip {
  border-radius: 0;
  border: 1px solid $gray-200;
  border-left: none;
  border-right: none;
  color: $gray-700;
  background-color: $gray-100;

  display: inline-flex;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
  line-height: 1;
  margin-left: 0;
  margin-right: 0;
  overflow: hidden;
  padding: 0 4px;
}
.chip-nav .chip-container:first-child .chip {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-left: 1px solid $gray-200;
  padding-left: 10px;
  padding-right: 4px;
}
.chip-nav .chip-container:last-child .chip {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-right: 1px solid $gray-200;
  padding-right: 10px;
  padding-left: 4px;
}
.chip-nav .chip-content {
  display: inline-flex;
  margin-right: -3px
}
.chip-nav .svg-arrow {
  stroke: #e9ecef;
  fill: rgb(249, 250, 251);
}
.chip-nav .chip-container .chip-content.success .chip {
  background-color: rgb(236, 253, 245);
  color: rgb(4, 120, 87);
  border-top-color: #c7ebd3;
  border-bottom-color: #c7ebd3;

  .svg-arrow {
    stroke: #c7ebd3;
    fill: rgb(236, 253, 245);
  }
}

.chip-nav .success .svg-arrow {
  stroke: #c7ebd3;
  fill: rgb(236, 253, 245);
}
.chip-nav .chip-container:first-child .chip-content.success .chip {
  border-left-color: #c7ebd3;
  border-top-color: #c7ebd3;
  border-bottom-color:#c7ebd3;
}
.chip-nav .chip-container:last-child .chip-content.success .chip {
  border-right-color: #c7ebd3;
  border-top-color: #c7ebd3;
  border-bottom-color: #c7ebd3;
}
