.modal-wrapper {
  @apply fixed z-20 inset-0 overflow-y-auto flex items-center;
}

#modal-background {
  @apply bg-gray-500 opacity-75 #{!important};
}

.modal-inner-wrapper {
  @apply flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0;
}

.modal-bg-wrapper {
  @apply fixed inset-0 transition-opacity;
}
.modal-bg {
  @apply absolute inset-0 bg-gray-500 opacity-75;
}
.modal-close {
  @apply hidden sm:inline-block sm:align-middle sm:h-screen;
}
.modal {
  @apply mx-auto inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full;
}
.modal-wrap {
  @apply bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4;
}
.modal-inner {
  @apply sm:flex sm:items-start;
}
.modal-icon-col {
  @apply mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10;
}
.modal-body {
  @apply p-8 pb-4 sm:text-left flex;

  p {
    @apply text-sm text-gray-500 mt-2;
  }
}
.modal-headline {
  @apply  text-lg leading-6 font-medium text-gray-900;
}
.modal-footer {
  @apply bg-gray-100 mt-3 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse;
}