.nav-link {
  @apply text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium;

  &.active {
    @apply bg-gray-900 text-white rounded-md ;
  }
}

//@media (min-width: 1024px) {
//  .nav-link {
//    @apply inline-flex my-0 mr-3;
//    line-height: 40px;
//  }
//}

/* Highlights unread notifications with background color */
[data-notifications-target="notification"]:not([data-read-at]) {
  @apply bg-gray-100;
}

/* Highlights uninteracted notifications with blue dot */
[data-notifications-target="notification"]:not([data-interacted-at]) {
  [uninteracted] {
    @apply block;
  }
}

.dropdown-menu {
  min-width: 100%;

  @screen lg {
    min-width: 200px;
  }
}
