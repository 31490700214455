.progress-bar-bg {
  @apply overflow-hidden h-2 text-xs flex rounded bg-purple-200;
  min-width: 350px;
}

.progress-bar {
  @apply shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-500;
  transition: .5s;
}

#percentage {
  @apply text-xs font-semibold inline-block text-purple-600;
  letter-spacing: normal !important;
}