.align-items-center {
  align-items: center!important;
}
.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

//--------------------------
// Typography
//--------------------------
html * {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}



//--------------------------
// Buttons & Links
//--------------------------
.btn-xs, .btn-group-xs > .btn {
  padding: 0.1rem 0.35rem;
  line-height: 1.375;
  border-color: transparent;
}
a.sort_link {
  color: unset;
  text-decoration: unset;
}
.btn-xs {
  height: unset;
}
.btn-icon {
  margin: unset;
  top: unset;

}

//--------------------------
// Tables
//--------------------------
.clickable-rows > tr,
.clickable-rows > tr td {
  cursor: pointer !important;
}
//td.actions > a {
//  z-index: 100;
//  position: relative;
//}
td.actions > a > span {
  z-index: -1;
  position: relative;
}
tr td.actions a {
  visibility: hidden;
}
//tr td.actions a.btn {
//  display: inline-block !important;
//}
tr:hover > td.actions a {
  visibility: visible;
}
//tr td.actions > a span {
//  line-height: 40px;
//}
.clickable-rows img {
  max-width: 150%;
  margin: -25% 0 -25% -25%;
  max-height: 160%;
}
.clickable-rows > tr > td:first-child {
  padding-left: 2rem;
}
tr.align-center > td {
  vertical-align: middle !important;
}

// Product image form
fieldset#images img {
  border-radius: 7px;
}


.pop-score {
  position: relative;
  margin-left: 8px;
  margin-right: 4px;
  font-weight: 500;
  z-index: 0;
}
.pop-score::before {
  content: "(";
  position: absolute;
  left: -3px;
  color: inherit;
  z-index: 550;
  font-weight: 500;
}
.pop-score::after {
  content: ")";
  position: absolute;
  right: -5px;
  color: inherit;
  z-index: 550;
  font-weight: 500;
}
.pop-score::before,
.pop-score::after {
  z-index: 10;
}
.pop-score svg {
  height: 10px;
  width: 10px;
  margin: 0 1px;
  bottom: -0.5px;
  position: relative;
}
.pop-score .bi.bi-arrow-up-short {
  width: 12px;
  left: -2px;
  position: relative;
}

.orders.index tr.align-center>td {
  vertical-align: middle!important;
  padding-top: 5px;
  padding-bottom: 5px;
}

//tbody td.actions {
//  display: flex;
//  align-items: stretch;
//}

//tr td.actions a.btn {
//  display: flex !important;
//}
//
//.actions a.btn {
//  line-height: 1;
//  padding: 5px;
//  border: 1px solid #7c3aed;
//  box-shadow: 0 0 5px -1px rgb(124 58 237 / 30%);
//  background: #fff;
//  align-items: center;
//  height: unset;
//}
//
//
////--------------------------
//// SweetAlert
////--------------------------
//.swal2-icon.swal2-warning {
//  //border-color: #ff5722;
//  //color: #ff5722;
//  border-color: #ffc107 !important;
//  color: #ffc107 !important;
//}
//.swal2-icon.swal2-success [class^=swal2-success-line] {
//  background-color: #4caf50 !important;
//}
//.swal-icon--error {
//  border-color: #e91e63 !important;
//}
//.swal-icon--error__line {
//  background-color: #e91e63 !important;
//}
//.swal-actions .btn + .btn,
//.swal-actions button + button {
//  margin-left: 10px;
//}
//
//
//--------------------------
// Animations
//--------------------------
// Countdown animation for notification controller
@keyframes notification-countdown {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}

//@keyframes fly-in-left{
//  0%{
//    opacity: 0;
//    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
//  }
//
//  60%{
//    opacity: 1;
//    transform: translate3d(-25px, 0, 0);
//  }
//
//  75%{
//    opacity: 1;
//    transform: translate3d(10px, 0, 0);
//  }
//
//  90%{
//    opacity: 1;
//    transform: translate3d(-5px, 0, 0);
//  }
//
//  100%{
//    opacity: 1;
//    transform: none;
//  }
//}
//
//.notification {
//  opacity: 0;
//  transform: translate3d(1500px, 0, 0);
//}
//
//.notify-animation {
//  animation: fly-in-left 0.6s ease-in-out 1s 1;
//  //opacity: 1;
//}

//.notification {
//  animation-delay: 1s;
//  animation: fade-in-bounce-right 1s ease-in-out 0.50s 1;
//}
#notifications {
  width: 400px;
  right: 20px;
  position: fixed;
  bottom: 0;
  top: 60px;
  justify-content: flex-start;
}

//--------------------------
// Charts
//--------------------------
div[id^='chart_'] {
  position: relative;
}
.chart_wrap {
  animation: fadeIn 1500ms cubic-bezier(.57,.25,.65,1) 1 forwards;
  opacity: 0;
  max-width: 140px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.chart_wrap + div {
  margin-top: -20px;

  .pop-score::after,
  .pop-score::before {

  }
}
.chart_wrap + div, .chart_wrap + div * {
  font-size: 12px;
}
.chart_wrap + div svg {
  bottom: -2px;
  line-height: 1.2;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

td.popularity {
  > div > div {
    transition: all .2s ease-in-out;
    transform: translate(0,0);
    opacity: 1;
  }
  &:hover > div > div:last-child {
    transform: translate(0,15px);
    opacity: 0;
  }
}


//--------------------------
// Products
//--------------------------
.products.new #articles_section,
.products.new #additional-info_section, {
  display: none;
}
#linked_products > .form-group.select {
  margin-bottom: 1.5rem;
}

//--------------------------
// Forms
//--------------------------
.help-info {
  color: var(--color-primary-500);
  margin-left: 6px;
  transform: translate(15px, 0);
  opacity: 0;
  transition: all .2s ease-in-out;
  font-size: 14px;
  display: inline-block;
}
div[class*=form-group]:hover .help-info {
  transform: translate(0,0);
  opacity: 1;
}

#price-grades_fieldset {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  margin-bottom: 8px;
}
#price-grades_fieldset > .row {
  margin-bottom: 0;
}
.grid > .row,
.grid > .row > div[class^='col-'][class*='form-group-'],
#price-grades_fieldset > .row > div[class^='col-'][class*='form-group-'] {
  width: 100%;
  display: block;
  flex-wrap: unset;
}
.tox.tox-tinymce {
  border-radius: 0.375rem;
  border-color: rgb(209,213,219) !important;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 5%) 0px 1px 2px 0px;
}

.blurred {
  position: relative;
  transition: all 1s cubic-bezier(0, 1, 0, 1);
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    filter: blur(2px);
    opacity: 1;
    z-index: 5;
    transition: all 1s cubic-bezier(0, 1, 0, 1);
  }
}
.blur.sharpen::before {
  -webkit-backdrop-filter: blur(0);
  backdrop-filter: blur(0);
  filter: blur(0);
  //transition: all 1s ease-in-out;
  opacity: 0;
}

//--------------------------
// Code
//--------------------------

pre {
  font-size: 14px;
}
code {
  border-radius: 8px;
}

//--------------------------
// Tooltips
//--------------------------
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

//--------------------------
// Popovers
//--------------------------
.popover {
  box-shadow: 0 10px 35px rgba(0,0,0,.1);

}

//--------------------------
// Utilities
//--------------------------
.translation_missing {
  background-color: red !important;
  color: white !important;
}
