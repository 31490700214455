form[class^=col-] {
  @apply mt-0 w-full;
}
form .row > * {
  @apply mt-4;
}
.form-group {
  @apply mb-6 relative;
}

[type='text'], [type='email'], [type='url'], [type='password'],
[type='number'], [type='date'], [type='datetime-local'],
[type='month'], [type='search'], [type='tel'], [type='time'],
[type='week'], [multiple], textarea, select {
  @apply text-sm;
}

/* non-input elements (like the Stripe card form) can be styled to look like an input */
div.form-control {
  //-webkit-appearance: none;
  //-moz-appearance: none;
  //appearance: none;
  //background-color: #fff;
  //border-width: 1px;
  //padding-top: .5rem;
  //padding-right: .75rem;
  //padding-bottom: .5rem;
  //padding-left: .75rem;
  //font-size: 1rem;
  line-height: 1.25rem !important;

  //@apply mt-1 text-sm block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50;
  @apply mt-1 text-sm block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500;
}

.form-control {
  @apply mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500;
  //@apply mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50;

  &.error {
    @apply border-red-300;
  }
}


.form-hint {
  @apply text-gray-600 text-xs italic;

  &.error {
    @apply text-red-500 text-sm italic leading-normal mt-1;
  }
}

.select {
  @apply block mt-1 rounded-md border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500;
}
div[class^=col-].select {
  box-shadow: none !important;
}

label,
.label {
  @apply block text-sm font-medium leading-5 text-gray-700 mb-1;

  abbr[title] {
    -webkit-text-decoration: none;
    text-decoration: none;
  }
}

label.select {
  box-shadow: none;
  @apply mt-0 mb-1;
}

.caret {
  @apply pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-800;
}

form.inline {
  .form-group {
    @apply flex justify-start items-center;
  }

  .form-group .input {
    @apply flex-1;
  }

  .form-group .label {
    @apply w-1/4;
  }
}

// Fix flatpickr
.flatpickr-current-month .flatpickr-monthDropdown-months {
  display: inline-block;
}

[type=checkbox] {
  @apply rounded border-gray-300 text-primary-600 shadow-sm focus:ring-primary-500 focus:border-primary-500 mr-3;

  &[disabled] {
    @apply bg-gray-200;
  }
}

.form-check {
  @apply flex;
}

.input-group {
  @apply mt-1 flex rounded-md shadow-sm;
  .form-control {
    //@apply mt-0 focus:ring-indigo-500 focus:border-indigo-500 flex-1 shadow-none block w-full px-3 py-2 rounded-r-md sm:text-sm border-gray-300;
    @apply mt-0 focus:ring-primary-300 focus:border-primary-300 flex-1 shadow-none block w-full px-3 py-2 rounded-r-md sm:text-sm border-gray-300;
  }
  .btn {
    height: 42px;
    //@apply -ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500;
    @apply -ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500;
  }
  .input-group-text {
    //@apply inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm;
    @apply inline-flex items-center px-3 rounded-l-md border border-gray-300 bg-gray-50 text-gray-500 sm:text-sm;
  }
  >:not(:first-child) {
    @apply -ml-px;
  }
  > :first-child {
    @apply rounded-none rounded-l-md;
  }
  > :last-child {
    @apply rounded-none rounded-r-md
  }
}

.input-group-badged {

  .input-group {
    @apply mt-1 relative rounded-md shadow-sm;

    .form-control {
      @apply rounded-md ;
    }
    .input-group-text {
      @apply inline-flex items-center py-1 px-2.5 rounded-l-md bg-gray-200 border-0 text-gray-700 text-xs;
      line-height: 1;

      &:last-child {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &[data-barcode='n/a'] .input-group-text {
    display: none;
  }

  &[data-barcode='Err'] .input-group-text,
  &[data-barcode='Unknown'] .input-group-text {
    @apply bg-red-100 text-red-800;
  }
}

.search-form > .input-group {
  margin-top: 0 !important;
  margin-left: 10px;
}
.search-form .btn .bi {
  margin-right: 8px;
}

.input-file {
  height: auto;
  z-index: 2;
  cursor: pointer;

  @apply opacity-0 inline-block pl-0 pr-0 py-3 px-3 overflow-hidden absolute border-none;

  + label {
    @apply inline-flex text-sm font-bold text-center px-4 py-0 rounded no-underline items-center justify-between cursor-pointer;

    height: 40px;
    line-height: 40px;
    transition: ease 0.3s background, ease 0.3s transform, ease 0.2s color;

    @extend .btn;
    @extend .btn-tertiary;
    //@extend .outline;

    &:focus {
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }
  }
}

input:disabled,
input.readonly,
select:disabled,
select.readonly{
  @apply cursor-not-allowed bg-gray-100 opacity-50;
}

.form-group-invalid label {
  @apply text-red-500;
}
.is-invalid {
  @apply border-red-500;
}
.invalid-feedback {
  @apply text-red-500 text-xs;
}
.form-group.display_link {
  @apply pb-3;
}

fieldset.form-group {
  @apply mb-0;
}

// PRODUCT FEATURES
.product_features .form-control,
.product_in_the_box .form-control {
  @apply border-0 shadow-none border-b border-dashed border-gray-300 rounded-none;
}
.product_features .input-group,
.product_in_the_box .input-group {
  @apply shadow-none m-0 relative;
}
.product_features button.btn.outline.array-remove-item,
.product_in_the_box button.btn.outline.array-remove-item {
  @apply text-gray-200 bg-transparent border-0;
}
.product_features .input-group:hover button.btn.outline.array-remove-item,
.product_in_the_box .input-group:hover button.btn.outline.array-remove-item {
  @apply text-gray-800;
}
.product_features .input-group::before,
.product_in_the_box .input-group::before {
  content: '-';
  display: block;
  z-index: 10;
  line-height: 42px;
  @apply relative text-gray-500 top-0 bottom-0 left-0;
}
.product_features .input-group .form-control:focus,
.product_in_the_box .input-group .form-control:focus {
  @apply border-l-8 -ml-2 shadow-none ring-0;
  border-left-style: solid;
  z-index: 2000;
}
.product_features .input-group .form-control:focus + button .bi,
.product_in_the_box .input-group .form-control:focus + button .bi {
  @apply text-primary-500;
}


[type='file'] {
  @apply p-3 block w-full rounded-md focus:ring-primary-500 focus:border-primary-500 text-sm;
}

.borderless-inputs .form-group,
.borderless-inputs input[type=text] {
  @apply border-none m-0 shadow-none rounded-none;
}

fieldset.group {
  padding: 8px;
  display: block;
  margin-top: 24px;
  @apply bg-gray-100 rounded-md;

  legend {
    padding: 3px 8px;
    font-size: small;
    text-transform: uppercase;
    border-radius: 4px;
    background: #fff;
    line-height: 1;
    align-items: center;
    display: flex;
    @apply border border-gray-200;

    .help-icon {
      font-style: normal;
      margin-left: 7px;
      color: var(--color-primary-500);
      position: relative;
      z-index: 8;
      margin-right: -2px;
      cursor: help;
      line-height: 9px;
      vertical-align: middle;

      &::before {
        line-height: unset;
      }
    }
  }

  input:disabled, input.readonly {
    --tw-bg-opacity: 1;
    background-color: #fff;
    cursor: not-allowed;

    &:hover {
      background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
    }
  }
}
.modal input[type=date] {
  border: none;
  background: transparent;
  width: 100%;
}
.date select,
.datetime select {
  display: inline;
  width: auto;
  margin-top: 0;
}
.nested-fields .row.mb-4 {
  margin-bottom: 0;
}
.display_link {
  max-width: calc(100% - 100px);
  @apply text-sm;
}
.form-check {
  align-items: center;

  .help-info {
    opacity: 1;
    color: #9ca3af;
  }
  .form-check-label {
    margin: 0;
  }
}
