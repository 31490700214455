.badge {
  @apply inline-flex items-center px-2 py-0.5 rounded-md text-xs font-medium;
  padding-top: 0.1rem;
  margin-top: 0.2rem;
  letter-spacing: 0 !important;

  &.badge-rounded {
    @apply rounded-xl;
    padding-left: 4px;
  }

  .bi-inline {
    top: -0.07rem;
  }

  span.bi {
    font-size: 0.85rem;
  }
}
.upcase {
  @apply uppercase font-medium;
}

.badge-gray {
  --tw-ring-color: rgb(107 114 128 / 0.1);
  @apply bg-gray-50 text-gray-700 ring-1 ring-inset;
}
.badge-red {
  --tw-ring-color: rgb(220 38 38 / 0.1);
  @apply bg-red-50 text-red-700 ring-1 ring-inset;
}
.badge-yellow {
  --tw-ring-color: rgb(255 193 7 / 0.3);
  @apply bg-yellow-50 text-yellow-700 ring-1 ring-inset;
}
.badge-green {
  --tw-ring-color: rgb(22 163 74 / 0.2);
  @apply bg-green-50 text-green-700 ring-1 ring-inset;
}
.badge-blue {
  --tw-ring-color: rgb(13 110 253 / 0.1);
  @apply bg-blue-50 text-blue-700 ring-1 ring-inset;
}
.badge-indigo {
  --tw-ring-color: rgb(102 16 242 / 0.1);
  @apply bg-indigo-50 text-indigo-700 ring-1 ring-inset;
}
.badge-purple {
  --tw-ring-color: rgb(111 66 193 / 0.1);
  @apply bg-purple-50 text-purple-700 ring-1 ring-inset;
}
.badge-pink {
  --tw-ring-color: rgb(214 51 132 / 0.1);
  @apply bg-pink-50 text-pink-700 ring-1 ring-inset;
}
.badge-oj {
  --tw-ring-color: rgb(253 126 20 / 0.2);
  background-color: #fff7ed;
  color: #f97316;
  border: 1px solid var(--tw-ring-color);
  //@apply bg-gray-50 text-gray-700 ring-1 ring-inset;
}
//.badge-orange {
//  --tw-ring-color: rgb(253 126 20 / 0.1);
//  @apply bg-orange-50 text-orange-700 ring-1 ring-inset;
//}

