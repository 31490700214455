body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
body.fixed {
  left: 0;
  top: 0;
  right: 0;
}

.main {
  flex: 1;
}

html {
  //font-size: 14px;
  //margin-left: calc(100vw - 100%);
  width:100vw;
  overflow-x:hidden;
}

//@media (min-width: 420px) {
//  html {
//    font-size:16px;
//  }
//}


a {
  @apply text-primary-500;

  &:hover,
  &:focus {
    @apply text-primary-600;

    transform: none;
  }
}

ul {
  @apply list-disc list-inside;
}

ol {
  @apply list-decimal list-inside;
}

.table {
  @apply w-full;
  th, td {
    @apply align-top;
  }

}

.table-responsive {
  @apply block w-full overflow-x-auto;
  -webkit-overflow-scrolling: touch;
}

.success-badge {
  @apply px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800;
}
.content-wrapper {
  @apply space-y-6;
}
.content-wrapper:not(.new):not(.edit):not(.advanced_search):not(.payload_data) {
  @apply bg-white rounded-lg shadow py-6;
}

.content-wrapper > div + table,
.content-wrapper > div + div:not(.card) {
  margin-top: 0 !important;
}

.flex-subheader {
  @apply flex flex-col sm:flex-row sm:flex-wrap sm:space-x-6;
}
.flex-sub-item {
  @apply mt-1 flex items-center text-sm text-gray-300;
}
.flex-sub-item a {
  @apply text-gray-300 hover:text-primary-500;
}
