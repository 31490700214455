html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  @apply font-inter;

  letter-spacing: -0.006em;

  &.text-xs, .text-xs,
  &.text-sm, .text-sm,
  &.text-md, .text-md,
  &.text-lg, .text-lg,
  &.text-xl, .text-xl,
  &.text-2xl, .text-2xl,
  &.text-3xl, .text-3xl,
  &.text-4xl, .text-4xl,
  &.text-5xl, .text-5xl,
  &.text-6xl, .text-6xl,
  &.text-7xl, .text-7xl,
  &.text-8xl, .text-8xl,
  &.text-9xl, .text-9xl {
    font-optical-sizing: inherit;
    letter-spacing: 0;

  }
}

.font-display {
  font-size: 4rem; // 64px

  @apply font-black leading-tight tracking-tight;
}


h1,
.h1 {
  //font-size: 2.5rem; // 48px

  @apply text-3xl text-white font-extrabold leading-tight tracking-tight sm:text-3xl sm:truncate;

}

h2,
.h2 {
  font-size: 2.25rem; // 36px

  @apply font-black leading-tight;
}

h3,
.h3 {
  //font-size: 1.5rem; // 24px

  @apply text-lg tracking-normal leading-6 font-medium text-gray-900;
}

h4,
.h4 {
  font-size: 1.125rem; // 18px

  @apply font-black leading-normal;
}

h5,
.h5 {
  @apply font-bold uppercase tracking-wide text-sm; // .875rem
}

.link {
  @apply text-primary-500;

  &:hover,
  &:focus {
    @apply text-primary-600;
  }
}

header h1 {
  line-height: 40px !important;
}


.text-primary {
  @apply text-primary-600;
}