//// Configuration
@import "~bootstrap/scss/functions";
@import "variables";
@import "~bootstrap/scss/mixins";
//@import "~bootstrap/scss/utilities";
//
//// Layout & components
//@import "~bootstrap/scss/root";
//@import "~bootstrap/scss/reboot";
//@import "~bootstrap/scss/type";
//@import "~bootstrap/scss/images";
//@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
//@import "~bootstrap/scss/tables";
//@import "~bootstrap/scss/forms";
//@import "~bootstrap/scss/buttons";
//@import "~bootstrap/scss/transitions";
//@import "~bootstrap/scss/dropdown";
//@import "~bootstrap/scss/button-group";
//@import "~bootstrap/scss/nav";
//@import "~bootstrap/scss/navbar";
//@import "~bootstrap/scss/card";
//@import "~bootstrap/scss/accordion";
//@import "~bootstrap/scss/breadcrumb";
//@import "~bootstrap/scss/pagination";
//@import "~bootstrap/scss/badge";
//@import "~bootstrap/scss/alert";
//@import "~bootstrap/scss/progress";
//@import "~bootstrap/scss/list-group";
//@import "~bootstrap/scss/close";
//@import "~bootstrap/scss/toasts";
//@import "~bootstrap/scss/modal";
//@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
//@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";

// Helpers
//@import "~bootstrap/scss/helpers";

//// Utilities
//@import "~bootstrap/scss/utilities/api";
@import "bootstrap-icons";

@import "~select2";

//@import "~tailwindcss/base";
//@import "~tailwindcss/components";
@tailwind base;
@tailwind components;

@import 'components/base';
@import 'components/layout';
//@import 'components/announcements';
@import 'components/alert';
//@import 'components/avatars';
@import 'components/typography';
@import 'components/buttons';
@import 'components/icons';
@import 'components/cards';
@import 'components/forms';
@import 'components/tables';
@import 'components/util';
@import 'components/nav';
//@import 'components/code';
//@import 'components/docs';
@import 'components/animation';
@import 'components/tabs';
@import 'components/pagination';
//@import 'components/connected_accounts';
//@import 'components/actiontext';
//@import 'components/direct_uploads';
//@import 'components/iframe';
@import 'components/badges';
@import 'components/dropdowns';
@import 'components/select2';
@import 'components/youtube';
@import 'components/modal';
@import 'components/progress_bar';
@import 'components/advanced_search';
@import 'components/lock_toggle';
@import 'components/syntax_highlight';
@import 'components/dashboard';

@import 'components/pipeline';

//@import "~tailwindcss/utilities";
@tailwind utilities;

@import '~tippy.js/dist/tippy';
@import '~tippy.js/themes/light';
@import '~tippy.js/dist/svg-arrow';
@import '~tippy.js/animations/shift-toward-extreme';



// Custom style
@import "components/addresses";
@import "custom";
