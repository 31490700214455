.dropdown-wrapper {
  @apply inline-block text-sm px-4 py-2 leading-none rounded no-underline hover:text-gray-900 mt-4 lg:mt-0;
}

.dropdown-menu {
  @apply origin-top-right absolute right-0 mt-2 w-64 py-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100;

  .dropdown-link {
    @apply group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900;

    .bi {
      font-size: large;
      margin-right: 14px;
      margin-left: 4px;
      top: -1px;
      position: relative;
    }
  }

  button.dropdown-link {
    @apply w-full text-left block;
  }
}

