.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.transition {
  transition: ease-in-out 0.2s all;
}

.flex-row {
  @apply lg:flex lg:items-center lg:justify-between;
}
a[aria-label='Powered by Tiny'] {
  display: none !important;
}

.disabled, .disabled > * {
  pointer-events: none;
  cursor: default;
}

.display_link,
.dont-break-out {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

}

.text-wrap {
  text-wrap: wrap;
}
.text-norm {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.011em;
}

// widths in multiple of 5%
$options: 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100;
@each $option in $options {
  .w-#{$option} {
    width: $option * 1%;
  }
}