.address-meta, .address-actions {
  padding: 1rem 0;
}

.address-meta {
  .customer_addresses_address_type {
    display: flex;
    align-items: center;
    gap: 10px;

    select {
      padding: 0.35rem 0.5rem;
      margin: 0;
    }
  }
  .customer_addresses_primary {
    align-items: center;
    display: flex;
  }

  .customer_addresses_address_type *, .customer_addresses_primary * {
    margin-bottom: 0;
  }

  border-top: 1px solid #e5e7eb;
  border-bottom: 1px solid #e5e7eb;
  margin-top: 1rem;
  padding-top: 0;
}
