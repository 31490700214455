.tab-active {
  //background: linear-gradient(116deg, #3957F4 22%, #2F87EE 90%);
  //border-radius: 4px 4px 0px 0px;

  a {
    //@apply text-white;

    &:hover,
    &:focus {
      //background: linear-gradient(308deg, #3957F4 24%, #2F87EE 80%);
      //border-radius: 4px 4px 0px 0px;

      @apply text-gray-300;
    }
  }
}

.nav-tabs {
  @apply border-b border-gray-200;

  .tab {
    position: relative;
    margin-bottom: -1px;

    @apply group inline-flex items-center text-gray-500 hover:text-gray-700;

    a {
      @apply border-transparent text-gray-500 hover:text-gray-700 py-4 px-1 border-b-2 font-medium text-sm;

      .bi {
        margin-right: 6px;
        font-size: 17px;
      }
    }

    &:hover a,
    &:focus a {
      border-bottom-width: 3px;
      @apply border-gray-300;
    }

    &.active a {
      border-bottom-width: 3px;

      @apply border-primary-500 text-primary-600 font-medium text-sm;
    }

  }
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.tabs {
  @apply block w-full focus:ring-primary-500 focus:border-primary-500 border-gray-300 rounded-md;
}
.tab-pane > table {
  margin-top: -23px;
}
