$primary: #5628EE;
$lightGrey: #99A3BA;

// Markup:
//    <label class="switch">
//      <input type="checkbox" checked>
//      <span>
//        <em></em>
//        <strong></strong>
//      </span>
//    </label>

.switch {
  height: 24px;
  display: block;
  position: relative;
  cursor: pointer;
  input {
    display: none;
    & + span {
      padding-left: 50px;
      min-height: 24px;
      line-height: 24px;
      display: block;
      color: $lightGrey;
      position: relative;
      vertical-align: middle;
      white-space: nowrap;
      transition: color .3s ease;
      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        border-radius: 12px;
      }
      &:before {
        top: 0;
        left: 0;
        width: 42px;
        height: 24px;
        background: #e5e7eb;
        transition: all .3s ease;
      }
      &:after {
        width: 18px;
        height: 18px;
        background: #fff;
        top: 3px;
        left: 3px;
        box-shadow: 0 1px 3px rgba(#121621, .1);
        transition: all .45s ease;
      }
      em {
        width: 8px;
        height: 7px;
        background: $lightGrey;
        position: absolute;
        left: 8px;
        bottom: 7px;
        border-radius: 2px;
        display: block;
        z-index: 1;
        transition: all .45s ease;
        &:before {
          content: '';
          width: 2px;
          height: 2px;
          border-radius: 1px;
          background: #fff;
          position: absolute;
          display: block;
          left: 50%;
          top: 50%;
          margin: -1px 0 0 -1px;
        }
        &:after {
          content: '';
          display: block;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          border: 1px solid $lightGrey;
          border-bottom: 0;
          width: 6px;
          height: 4px;
          left: 1px;
          bottom: 6px;
          position: absolute;
          z-index: 1;
          transform-origin: 0 100%;
          transition: all .45s ease;
          transform: rotate(-35deg) translate(0, 1px);
        }
      }
      strong {
        font-weight: normal;
        position: relative;
        display: block;
        top: 1px;
        &:before,
        &:after {
          font-size: 14px;
          display: block;
          font-family: inherit;
          -webkit-backface-visibility: hidden;
        }
        &:before {
          content: 'Unlocked';
          transition: all .3s ease .2s;
        }
        &:after {
          content: 'Locked';
          opacity: 0;
          visibility: hidden;
          position: absolute;
          left: 0;
          top: 0;
          transition: all .3s ease;
          transform: translate(2px, 0);
          @apply text-primary-500;
        }
      }
    }
    &:checked {
      & + span {
        &:before {
          @apply bg-primary-100;
        }
        &:after {
          background: #fff;
          transform: translate(18px, 0);
        }
        em {
          transform: translate(18px, 0);
          @apply bg-primary-500;
          &:after {
            @apply border-primary-500;
            transform: rotate(0deg) translate(0, 0);
          }
        }
        strong {
          &:before {
            opacity: 0;
            visibility: hidden;
            transition: all .3s ease;
            transform: translate(-2px, 0);
          }
          &:after {
            opacity: 1;
            visibility: visible;
            transform: translate(0, 0);
            transition: all .3s ease .2s;
          }
        }
      }
    }
  }
}
fieldset label.switch {
  display: inline-block;
  margin-bottom: 0;
  margin-left: 8px;
  margin-top: 0;
  vertical-align: middle;
  height: auto;
}

fieldset .switch input + span {
  min-height: 18px;
  line-height: 18px;
  padding-left: 40px;
}
fieldset .switch input + span:before {
  height: 18px;
  width: 36px;
}

fieldset .switch input + span:after {
  width: 16px;
  height: 16px;
  top: 1px;
  left: 1px;
}
fieldset .switch input + span em {
  width: 8px;
  height: 7px;
  left: 5px;
  bottom: 4.5px;
}
fieldset .switch input + span strong:before,
fieldset .switch input + span strong:after {
  font-size: 11px;
  line-height: 16px;
}
