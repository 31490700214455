#product_search > fieldset + fieldset {
  @apply mt-8;
}
#product_search fieldset {
  @apply p-6 border border-gray-200 rounded-md shadow-sm;
  //display: flex;
  //margin-bottom: 40px;
  //padding: 15px;
  //border: 1px solid #eaeaea;
}
#product_search fieldset > legend {
  @apply px-3 text-sm;
}
#product_search fieldset.fs-lv1 > legend {
  @apply text-md px-4 font-medium;
}
#product_search fieldset.fs-lv1 > fieldset {
  @apply bg-gray-100 bg-opacity-75;
}
#product_search fieldset fieldset {
  @apply mb-4;
}
#product_search > fieldset:nth-child(3) {
  display: block;
  @apply mb-4;
}
#product_search select, #product_search input[type=text] {
  @apply rounded-sm shadow-sm mx-1 border-gray-300;
  padding: 3px 35px 3px 7px;
  background-position: right 0.2rem center;
}
#product_search legend select {
  @apply rounded-sm shadow-sm mx-2 border-none bg-gray-100 bg-opacity-75;
  padding: 2px 35px 2px 7px;
  background-position: right 0.2rem center;
}
#product_search .remove_fields {
  margin-left: 5px;
  margin-right: 5px;
}
#product_search .sort select {
  @apply rounded-sm shadow-sm mx-1;
  padding: 3px 35px 3px 7px;
  background-position: right 0.2rem center;
}
#product_search .sort .fields {
  width: 33%;
  display: inline-block;
  margin-bottom: 8px;
}
#product_search .sort .fields > select:first-child {
  margin-left: 0;
}
#product_search .sort .fields > select + select {
  @apply mr-0 #{! important};
}
#product_search .sort .fields .remove_fields {
  @apply ml-0;
}
#product_search fieldset > legend {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  background: #fff;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  padding-top: 3px;
  padding-bottom: 3px;
}
#product_search fieldset.fs-lv1 > fieldset > fieldset {
  background: #fff;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
}
#product_search fieldset.fs-lv1 > fieldset > fieldset > fieldset {
  @apply bg-gray-100 bg-opacity-75;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
}
#product_search .fields.condition {
  position: relative;
}

#product_search .fields.condition legend {
  right: 10px;
  position: absolute;
  padding: 0 !important;
}
#product_search button + fieldset {
  @apply mt-4;
}
#product_search input[type=checkbox],
#product_search input[type=checkbox] + label {
  display: inline-block;
}
#product_search input.btn.btn-primary, section {
  display: inline-block;
}
#product_search .btn-primary {
  float: right;
}
.advanced_search td:first-child {
@apply font-medium text-gray-900;
}
