.btn {
  @apply inline-flex text-sm font-semibold text-center px-4 py-0 rounded no-underline items-center justify-between shadow-sm focus:outline-none focus:ring-2 focus:ring-primary-500;

  height: 40px;
  line-height: 40px;
  transition: ease 0.3s background, ease 0.3s transform, ease 0.2s color;

  &:hover,
  &:focus {
    @apply cursor-pointer;
  }
  &.outline {
    @apply shadow-none;
  }

}

.btn-small, .btn-sm {
  @apply px-3 text-sm font-normal;

  height: 28px;
  line-height: 1;
}

.btn-large {
  @apply text-base px-6;

  height: 48px;
  line-height: 48px;
}

.btn-primary {
  @apply bg-primary-600 text-white;

  &:hover,
  &:focus {
    @apply bg-primary-500 text-white;
  }

  &.outline {
    @apply bg-transparent border border-primary-500 text-primary-500 shadow-none;

    &:hover,
    &:focus {
      @apply bg-primary-500 text-white;
    }
  }
}

.btn-secondary {
  @apply bg-secondary-600 text-white;

  &:hover,
  &:focus {
    @apply bg-secondary-500 text-white;
  }

  &.outline {
    @apply bg-transparent border border-secondary-600 text-secondary-600 shadow-none;

    &:hover,
    &:focus {
      @apply bg-secondary-500 text-white;
    }
  }
}

.btn-tertiary {
  @apply bg-tertiary-600 text-white;

  &:hover,
  &:focus {
    @apply bg-tertiary-500 text-white;
  }

  &.outline {
    @apply bg-transparent border border-tertiary-500 text-tertiary-500 shadow-none;

    &:hover,
    &:focus {
      @apply bg-tertiary-500 text-white;
    }
  }
}

.btn-danger {
  @apply bg-danger-600 text-white;

  &:hover,
  &:focus {
    @apply bg-danger-500 text-white;
  }

  &.outline {
    @apply bg-transparent border border-danger-500 text-danger-500 shadow-none;

    &:hover,
    &:focus {
      @apply bg-danger-500 text-white;
    }
  }
}

.btn-white {
  @apply bg-white border border-gray-300;

  &:hover,
  &:focus {
    @apply bg-gray-100;
  }

  &.primary {
    @apply text-primary-600;
  }

  &.secondary {
    @apply text-secondary-600;
  }

  &.tertiary {
    @apply text-tertiary-600;
  }

  &.gray {
    @apply text-gray-700;
  }
}

.btn-link {
  @apply text-primary-500 bg-transparent shadow-none;

  &:hover,
  &:focus {
    @apply text-primary-600;

    transform: none;
  }
}
.btn-default {
  @apply inline-flex items-center text-white bg-gray-600 hover:bg-gray-700;

  &:hover,
  &:focus {
    @apply text-white;
  }
}

.btn-default-white {
  @apply mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm;
}

.btn-expanded {
  @apply block w-full;
}

.btn-inline-group {
  @apply relative z-0 inline-flex shadow-sm rounded-md;

  .btn {

    padding: 5px 10px;
    color: #8b5cf8;
    border: 1px solid #7c3aed;
    box-shadow: 0 0 5px -2px rgb(124 58 237 / 30%);
    background: #fff;
    align-items: center;
    height: unset;
    @apply relative m-0 inline-flex items-center rounded-md border bg-white text-sm font-medium hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500;

    &:first-child {
      @apply rounded-r-none
    }
    &:last-child {
      @apply rounded-l-none;
    }

    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
  }

  .btn + .btn {
    margin-left: -1px;
  }

  &.btn-primary-group .btn {
    @apply border-primary-300 text-primary-500;
  }
}

.btn-group .btn {
  margin-right: 12px;
}
.btn-group .btn:last-child {
  margin-right: 0;
}

.btn [class^=bi-]::before, .btn [class*=" bi-"]::before {
  top: -0.5px;
  //-webkit-text-stroke: 1px white;
  position: relative;
}

.btn:not(.btn-primary).disabled, .btn:not(.btn-primary):disabled, fieldset:not(.btn-primary):disabled .btn {
  //pointer-events: none;
  opacity: .50;
  cursor: not-allowed;
}
.btn.btn-primary.disabled, .btn.btn-primary:disabled {
  @apply bg-primary-600 text-white;
  opacity: .50;
  cursor: not-allowed;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-light {
  @apply bg-gray-100 text-gray-800;

  &:hover,
  &:focus {
    @apply bg-gray-200 text-gray-800;
  }
}
