//.icon-xs {
//  width: 12px;
//  height: auto;
//}
//
//.icon-sm {
//  width: 16px;
//  height: auto;
//}
//
//.icon {
//  width: 20px;
//  height: auto;
//}
//
//.icon-lg {
//  width: 32px;
//  height: auto;
//}
//
//.icon-xl {
//  width: 36px;
//  height: auto;
//}
//
//.icon-2xl {
//  width: 48px;
//  height: auto;
//}
//
//.icon-3xl {
//  width: 64px;
//  height: auto;
//}

.flex-subheader [class^=bi-]::before, .flex-subheader [class*=" bi-"]::before {
  font-size: initial !important;
  position: relative;
}
.bi-inline {
  @apply mr-1 flex-shrink-0 relative;
  top: -0.08rem;
}
.btn > .bi.bi-arrow-clockwise  {
  margin-right: 4px;
}
.btn .bi.bi-plus {
  font-size: 1.2rem;
  top: -1.5px;
  position: relative;
}